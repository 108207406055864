.record_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7d4;
  z-index: 20;
  animation: recorderAnimation 0.2s linear 1;
}
.record_btn {
  border: none;
  outline: none;
  background-color: #f1a200c2;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
}
.recorder {
  background-color: #eee;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding-bottom: 25px;
}
@keyframes recorderAnimation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
