.chat_box_heading {
  font-family: Inter;
  font-size: 30px;
  font-weight: bolder;
  padding-left: 60px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.audio_label {
  position: absolute;
  right: 20px;

  bottom: 15px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  color: white;

  border-radius: 50%;
  background-color: #f1a100ce;
  transition: all linear 0.2s;
}
.upload_label {
  position: absolute;
  right: 65px;

  bottom: 15px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  color: white;

  border-radius: 50%;
  background-color: #f1a100ce;
  transition: all linear 0.2s;
}
.upload_btn {
  font-size: 20px;
  /* color: rgba(18, 18, 18, 0.557) !important; */
}
.voice_btn {
  font-size: 20px;
  /* color: rgba(18, 18, 18, 0.557) !important; */
}
.upload_label:hover {
  background-color: #f1a200;
  transform: scale(1.1);
}
.audio_label:hover {
  background-color: #f1a200;
  transform: scale(1.1);
}
.uploadedFile {
  cursor: pointer;
  margin-top: 10px;
  transition: transform linear 0.2s;
}
.uploadedFile:hover {
  transform: scale(1.2);
}
.fileIcon {
  background-color: #f7f7f7;
  width: 80%;
  animation: showFileIcon 1s 1;
  transform: translateY(-50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  bottom: 0;
  left: 50px;
}
.fileIcon .ant-spin-spinning {
  position: absolute !important;
  right: 35%;
  top: 50%;
}
.audio_record .ant-spin-spinning {
  position: relative !important;
  right: 0px;
  top: 0px;
}
.fileIconMsg svg {
  width: 50px !important;
}
.fileIcon svg {
  width: 50px !important;
}
@keyframes showFileIcon {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(-50px);
  }
}
.load_more_btn {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bolder;
  color: #aaa;
  border-bottom: 2px solid #aaa;
}
.load_more_btn:hover {
  color: #f1a200;
  border-color: #f1a200;
}

@media only screen and (max-width: 768px) {
  .chat_box_heading {
    padding-left: 50px;
  }
}
@media only screen and (max-width: 550px) {
  .chat_box_heading {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 420px) {
  .chat_box_heading {
    padding-left: 10px;
  }
  .linkPreview_container {
    width: 200px !important;
  }
  .msg_link {
    width: 200px;
  }
  .chat_box_wrapper {
    height: 65vh !important;
    max-height: 65vh !important;
  }
  .chat_input {
    bottom: 70px;
  }
  .chat_send_logo {
    bottom: 60px;
  }
}
