.hoverOnDots {
  margin-right: 15px;
  cursor: pointer;
}
.group_dots_wrapper:hover g {
  fill: #f1a200;
}
.group_edit_dropdown {
  position: absolute;
  display: none;
  top: 25px;
  right: 10px;
  cursor: pointer;
  border-radius: 12px;
  width: max-content;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
  border: solid 2px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  z-index: 5;
}

.group_edit_dropdown p {
  padding: 8px 25px 8px 8px;
  margin: 5px;
  border-radius: 8px;
  font-weight: bolder;
  font-size: 13px;
  cursor: pointer;
}
.group_edit_dropdown p:hover {
  background-color: rgba(241, 161, 0, 0.17);
}

.group_dots_wrapper {
  position: relative;
  align-self: flex-start;
  height: 10px;
  /* width: 30px; */
}
.group_dots_wrapper:hover .group_edit_dropdown {
  display: block;
}
