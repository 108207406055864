.login_boy_box {
  position: absolute;
  width: 25%;
  top: 0px;
  right: -45px;
}
.login_girl_box {
  position: absolute;
  width: 25%;
  left: -45px;
  bottom: 0px;
}
.login_boy_img {
  width: 100%;
}
.login_girl_img {
  width: 100%;
}
.download_os {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 10px 20px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 12px;
  background-color: #f1a200;
}
.download_os:hover {
  color: white;
}
.login_plugLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login_plugLogo img {
  width: 320px;
  height: 80px;
}
.login_copyright {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.login_copyright img {
  width: 122px;
  height: 20px;
}
.login_plugLogo button {
  margin-top: 60px;
  width: 300px;
  height: 50px;
  color: white;
  outline: none;
  border: none;
  /* padding: 20px 58px 17px 59px; */

  font-size: 18px;
  font-weight: bold;
  border-radius: 12px;
  background-color: #f1a200;
  cursor: pointer;
}
.login_pop_up {
  position: absolute;
  top: 50%;
  right: 50%;
  padding: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transform: translate(50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.login_ui img {
  width: 30px !important;
}
.firebaseui-idp-icon-wrapper {
  margin-right: 10px;
}
.firebaseui-list-item {
  margin-bottom: 20px;
}
.firebaseui-list-item::marker {
  content: "";
}
.firebaseui-idp-button {
  border: none;
  background-color: rgba(241, 161, 0, 0.365) !important;
  padding: 10px 30px;
  border-radius: 15px;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: max-content;
}
.firebaseui-idp-button:hover {
  background-color: rgba(241, 161, 0, 0.6) !important;
}
.login_cancel_btn path {
  fill: #f1a200;
}
.firebaseui-idp-text-short {
  display: none;
}
@media only screen and (max-width: 768px) {
  .login_boy_box {
    width: 30%;
    right: -25px;
  }
  .login_girl_box {
    width: 30%;
  }
  .login_plugLogo button {
    width: 270px;
  }
  .login_plugLogo img {
    width: 330px;
    height: 80px;
  }
  .login_pop_up {
    padding: 70px 50px;
  }
}

@media only screen and (max-width: 550px) {
  .login_boy_box {
    bottom: 0px;
    top: 50vh;
    width: 40%;
    right: -7px;
  }
  .login_girl_box {
    width: 40%;
  }
  .login_plugLogo {
    top: 30%;
  }
  .login_plugLogo img {
    width: 330px;
    height: 80px;
  }
  .login_pop_up {
    top: 30%;
  }
}
