.App {
  text-align: center;
}
body {
  overflow: hidden;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Comfortaa";
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #d8d8d8;
}
*::-webkit-scrollbar-thumb {
  background: rgb(241, 162, 0);
  border-radius: 8px;
}
.bg-page {
  background-color: rgb(241, 162, 0);
  height: 100vh;

  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -10;
  overflow: hidden;
}
.main-page {
  width: 88%;
  margin: 0px auto;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 2px 20px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .main-page {
    width: 92%;
  }
}
@media only screen and (max-width: 550px) {
  .main-page {
    width: 96%;
  }

  *::-webkit-scrollbar {
    width: 4px;
  }
  *::-webkit-scrollbar-track {
    background: #d8d8d8;
  }
  *::-webkit-scrollbar-thumb {
    background: rgb(241, 162, 0);
    border-radius: 4px;
  }
}
