.linkPreview_container {
  width: 250px !important;
}
.msg_link {
  display: inline-block;
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 420px) {
  .linkPreview_container {
    width: 200px !important;
  }
  .msg_link {
    width: 200px;
  }
}
