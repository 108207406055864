.modal_box {
  background-color: rgba(255, 255, 255, 0.9);

  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.modal_container {
  width: 60%;
  height: 70%;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
  border: solid 2px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  position: relative;
}
.modal_crg_img {
  height: 65px;
  margin-bottom: 20px;
}
.modal_crg_input {
  width: 60%;
  border-radius: 12px;
  border: solid 2px #f1a200;
  outline: none;
  padding: 5px;
  font-size: 25px;

  color: #f1a200;
  font-weight: bolder;
  letter-spacing: 0.5px;
  text-align: center;
  margin-top: 25px;
}
.modal_crg_button {
  /* flex: 1; */
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 10px 20px;
  border-radius: 15px;
  background-color: #f1a200;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
}
.modal_crg_cross_logo {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.modal_crg_arr_logo {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
.modal_crg_add_box {
  flex: 1;
  display: flex;
  margin-top: 40px;
  align-items: stretch;
}
.modal_crg_add {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  overflow: hidden;
}
.modal_crg_add input {
  width: 85%;
  border: 2px solid #e1e1e1;
  padding: 5px 10px;
  outline: none;
  border-radius: 8px;
  font-weight: bolder;
  margin-bottom: 20px;
}
.modal_crg_add input:focus {
  color: #f1a200;
  border-color: #f1a200;
}
.modal_crg_list {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  align-self: flex-start;
}
.modal_crg_list h3 {
  font-weight: bolder;
  font-size: 14px;
  font-family: "Inter";
  width: 80%;
}
.search_user_img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #ededed;
}
.search_user_row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  /* cursor: pointer; */
  border-radius: 8px;
  margin-bottom: 5px;
}
.search_user_row:hover {
  background-color: rgba(241, 161, 0, 0.3);
}
.search_user_add_button {
  border: none;
  outline: none;
  padding: 5px 12px;
  border-radius: 8px;
  background-color: #00bf19;
  color: white;
  margin-left: 5px;
  cursor: pointer;
}
.search_user_remove_button {
  border: none;
  outline: none;
  color: #f1a200;
  font-weight: bold;
  background-color: transparent;
  font-weight: bold;
  font-size: 1em;
}

@media only screen and (max-width: 768px) {
  .modal_container {
    width: 80%;
    height: 80%;
  }
}
@media only screen and (max-width: 550px) {
  .modal_container {
    width: 90%;
    height: 90%;
  }
}
@media only screen and (max-width: 420px) {
  .modal_container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .modal_crg_input {
    width: 90%;
  }
  .modal_crg_button {
    width: 90%;
    bottom: 60px;
  }
  .modal_crg_add_box {
    flex-direction: column;
  }

  .modal_mobile_view_crg > div {
    height: 70px !important;
    max-height: 80px !important;
  }
  .modal_mobile_view_crg {
    height: 100px;
    flex: 0.3;
  }
  .modal_crg_list {
    align-self: stretch;
  }
  .modal_crg_list h3 {
    width: 90%;
  }
  .modal_crg_list > div {
    width: 97% !important;
  }
  .create_grp_btn {
    bottom: 60px !important;
    width: 90%;
  }
  .grp_setting_grp_name {
    width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: wrap;
  }
}
